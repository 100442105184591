@import url("https://fonts.googleapis.com/css?family=Fira+Sans");

$navy: #1f2b2e;
$blue: #0e4368;
$water: #3bade1;
$green: #78b636;

html, body {
  font-family: 'Fira Sans', sans-serif;
}

.app {
  h1 {
    font-size: 2.25rem;
  }

  h2 {
    font-size: 1.75rem;
  }

  h3 {
    font-size: 1.25rem;
  }

  h4 {
    font-size: 1.0rem;
  }

  p, span {
    font-size: 0.8rem;
  }

  .navbar {
    box-shadow: 0px 2px 8px #888;
    z-index: 999;
  }

  .section {
    height: 100vh;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    &:first-child {
      box-shadow: 0px 2px 8px #888;
    }
  }

  .section.map {
    .map-and-context {
      -webkit-box-flex: 1 1 auto;
      -moz-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      cursor: crosshair;
      
      .map-wrapper {
        height: calc(100vh - 68.5px); // header height = 68.5px
        width: 100%;
        cursor: crosshair;
        .leaflet-bottom {
          bottom: 32px;
          margin-bottom: 0 !important;
        }
        &.big-context {
          .leaflet-bottom {
            bottom: 206px;
          }
        }
      }
      
      .leaflet-container {
        height: 100%;
        width: 100%;
        cursor: crosshair;

        .leaflet-touch,
        .leaflet-retina,
        .leaflet-fade-anim,
        .leaflet-grab,
        .leaflet-touch-drag,
        .leaflet-touch-zoom,
        .leaflet-pane,
        .leaflet-map-pane,
        .leaflet-tile-pane,
        .leaflet-layer,
        .leaflet-interactive,
        .leaflet-clickable {
          cursor: crosshair;
        }

        .leaflet-control-layers {
          border: none;
          background-color: transparent;
          &-expanded {
            background: white;
            padding: 6px 8px 0 10px;
          }
        }
        .leaflet-left {
          .leaflet-control-zoom {
            display: none;
          }
        }
        

        .leaflet-control-layers-toggle {
          border-radius: 100%;
          background-image: url("images/layers.png");
          background-color: white;
          background-position: center center;
          box-shadow: 0 .125rem .25rem rgba(0,0,0,.075); 
        }
        .leaflet-control-layers-base {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 147px;
          label {
            flex-basis: 62px;
            padding: 8px;
            input {
              display: none;
              & + span {
                &:before {
                  content: '';
                  display: block;
                  vertical-align: text-top;
                  width: 38px;
                  height: 38px;
                  background-color: white;
                  border: 2px solid white;
                  border-radius: 8px;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: 100%;
                  margin-bottom: 6px;
                  overflow: hidden;
                }
              }
              &:checked + span:before {
                border-color: #1890ff;
              }
            }
            &:nth-child(1) {
              margin-bottom: 12px;
              span:before {
                background-image: url("images/map1.png");   
              }
            }
            &:nth-child(2) {
              margin-bottom: 12px;
              span:before {
                background-image: url("images/map2.png");   
              }
            }
            &:nth-child(3) {
              span:before {
                background-image: url("images/map3.png");   
              }
            }
            &:nth-child(4) {
              span:before {
                background-image: url("images/map4.png");   
              }
            }
          }
        }
        .leaflet-control-zoom {
          border: none;
          box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
        }
        .leaflet-control-attribution {
          display: none;
        }
        .leaflet-control-scale {
          margin-bottom: 10px;
          margin-left: 10px;
        }
        .leaflet-control-scale-line {
          background: rgba(255, 255, 255, 0.7);
          border: none;
          border-bottom: 2px solid black;//#1890ff;
          padding: 8px;
          box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
          font-size: 0.7rem;
        }
      }

      .context {
        position: relative;
        bottom: 250px;
        height: 250px;
        overflow: hidden auto; //hidden;
        padding: 10px;
        color: white;
        background: rgba(1, 1, 1, 0.7);
        z-index: 100000;
        
        .info {
          padding-top: 20px;
        }

        .context-title {
          text-align: center;

          ul {
            text-align: left;
          }

          span.value {
            font-weight: 800;
            white-space: nowrap;
          }
        }

        .chart-block {
          margin-top: -1rem;
        }

        span {
          color: white;
        }

        .loader-padding {
          padding-top: 20px;
        }

        .feature-context {
          font-size: 0.6rem
        }

        .map-selections {
          h4 {
            font-weight: 800;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .category {
            margin-bottom: 0.8rem;

            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
              margin-left: 0;
            }
            .ant-checkbox-wrapper {
              white-space: nowrap;
              min-width: 100px;
            }
            & > .ant-checkbox-wrapper {
              span {
                font-size: 0.7rem;
              }
            }
          }

          .category-label {
            font-size: 0.8rem;
          }

          span {
            font-size: 0.6rem;
            white-space: nowrap;
          }
        }

        .context-collapse-btn {
          position: absolute;
          left: 5px;
          top: 3px;
        }

        .context-locked-btn {
          position: absolute;
          left: 40px;
          top: 3px;
        }

        .hide {
          display: none;
        }
      }
    }
  }

  .section.about {
    .info {
      padding: 3rem 0;
      display: flex;
      flex: 1 1 auto;
      background-color: $navy;
      color: white;
      flex-wrap: wrap;

      .info-col {
        text-align: center;
        white-space: nowrap;
        padding-bottom: 3rem;
        // width: 24%;

        img {
          width: 9rem;
        }
      }

      .icon {
        height: 35%; //55%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .description {

        p.label {
          font-size: 1.2rem;
          text-decoration: underline;
        }

        p.value {
          font-size: 1.2rem;
          font-weight: 800;
          
        }
      }
    }

    .footer {
      color: $blue;
      background-color: white;
      width: 100%;
      height: 200px;
      padding: 30px 20px;

      p {
        font-size: 1.2rem;
      }
      
      .logo {
        max-height: 80px;
      }
      
      .version {
        position: absolute;
        bottom: -20px;
        right: 20px;
      }
    }
  }

  .logo {
    max-width: 250px;
  }

  .bold {
    font-weight: 800;
  }

  .center {
    text-align: center;
  }

  .block {
    display: block;
  }
}
.bg-dark-08 {
  background-color: rgba(52, 58, 64, 0.8);
}

@media (max-width: 1440px)  {
  .app .section.about .info .description p {
    &.value, &.label {
      font-size: 1.1rem;
    }
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .app .section.about .info .description p {
    &.value, &.label {
      font-size: 1rem;
    }
  }
}
@media (max-width: 767px)  {
  .app .section.about .info .description p {
    &.value, &.label {
      margin-bottom: 0.5rem;
    }
  }
}
@media (max-width: 360px)  {
  .app .section.about .info .description p {
    &.value, &.label {
      font-size: 1rem;
    }
  }
}

@import 'antd-overrides';
.loader {
  font-size: small;
  
  .lds-ripple {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
    margin-left: 10px;
  }
  .lds-ripple div {
    position: absolute;
    border: 3px solid white;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 14px;
      left: 14px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: -1px;
      left: -1px;
      width: 29px;
      height: 29px;
      opacity: 0;
    }
  }  
}

.inline-loader {
  display: table;

  span {
    display: table-cell;
  }

  span.loader {
    vertical-align: middle;
  }

  span.status {
    vertical-align: middle;
    word-break: break-word;
  }
}